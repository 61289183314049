<template>
  <div
    id="services-container"
    class="max-w-[1440px] mx-auto flex flex-wrap justify-center gap-4"
  >
    <DefineServiceCard v-slot="{ image, imageSet, title, content, link }">
      <NuxtLink
        :to="link"
        class="group flex flex-col items-center max-md:min-w-[120px] max-lg:min-w-[180px] min-w-[250px] max-w-[275px] text-neutral"
      >
        <img
          :src="image"
          :srcset="imageSet"
          loading="lazy"
          alt="Your image"
          class="w-[90px] h-[90px] mx-auto object-cover object-center mb-2"
          width="90"
          height="90"
        />
        <h3
          class="w-full text-xl max-md:text-base max-lg:text-lg text-center leading-7 font-bold mb-4"
        >
          {{ title }}
        </h3>
        <p class="w-full max-md:text-sm text-base text-center leading-5 px-2">
          {{ content }}
        </p>
      </NuxtLink>
    </DefineServiceCard>
    <ServiceCard
      v-for="(item, index) in processedItems"
      :key="index"
      v-bind="item"
    ></ServiceCard>
  </div>
</template>

<script setup lang="ts">
import { createReusableTemplate } from '@vueuse/core';

const [DefineServiceCard, ServiceCard] = createReusableTemplate<{
  image: string;
  imageSet: string;
  title: string;
  content: string;
  link: string;
}>();

interface Service {
  icon: string;
  title: string;
  content: string;
  link: string;
}

const props = defineProps({
  items: {
    type: Array as PropType<Service[]>,
    default: () => []
  }
});

const {
  public: { cloudflareImageProcessing: cfUrl }
} = useRuntimeConfig();

const altHost = 'https://pfsrealty.com';

const processedItems = computed(() => {
  return props.items.map(item => {
    const image = `${cfUrl}/image?w=90&h=90&f=webp&fit=scale-down&image=${altHost}${item.icon}`;
    return {
      ...item,
      image,
      imageSet: `${image} 1x, ${cfUrl}/image?w=180&h=180&f=webp&fit=scale-down&image=${altHost}${item.icon} 2x`
    };
  });
});
</script>
